import gastro from './../assets/images/works/gastrohouse.jpg';
import eastroom1 from './../assets/images/works/eastroom1.jpg';
import cibc1 from './../assets/images/works/cibc1.jpg';
import cibc2 from './../assets/images/works/cibc2.jpg';
import eastroom2 from './../assets/images/works/eastroom2.jpg';
import europro from './../assets/images/works/europro.jpg';
import tribute from './../assets/images/works/tributeplaza.jpeg';
import telus from './../assets/images/works/telus.jpg';
import prisma from './../assets/images/works/prisma.jpg';
import rocket from './../assets/images/works/rocket.jpg';
import summerhill from './../assets/images/works/summerhill.jpg';
import claremont from './../assets/images/works/claremont.jpg';

export const projectsData = [
  {
    id: 1,
    title: 'Prohibission Gastro House, Yonge & Eglinton',
    location: 'Toronto, Ontario',
    value: '$80,000',
    img: gastro,
  },
  {
    id: 2,
    title: 'Eastroom, 50 Carroll St',
    location: 'Toronto, Ontario',
    value: '$700,000',
    img: eastroom1,
  },
  {
    id: 3,
    title: 'CIBC Curtain Wall Replacement',
    location: 'Wallaceburg, Ontario',
    value: '$90,000',
    img: cibc1,
  },
  {
    id: 4,
    title: 'CIBC Windows and Entrance Replacement',
    location: 'Colborne, Ontario',
    value: '$55,000',
    img: cibc2,
  },
  {
    id: 5,
    title: 'Eastroom - Hullmark, 507 King St. East',
    location: 'Toronto, Ontario',
    value: '$1,800,000',
    img: eastroom2,
  },
  {
    id: 6,
    title: 'EuroPro 5001 Yonge, Curtain Wall Replacement',
    location: 'North York, Ontario',
    value: '$175,000',
    img: europro,
  },
  {
    id: 7,
    title: 'My Tribute Plaza',
    location: '12840 Hwy 27, Nobelton, Ontario',
    value: '$N/A',
    img: tribute,
  },
  {
    id: 8,
    title: 'Telus Building Curtain Wall Replacement',
    location: 'Toronto, Ontario',
    value: '$N/A',
    img: telus,
  },
  {
    id: 9,
    title: 'Prisma 61 Townhouses Windows Replacement',
    location: 'Whitby, Ontario',
    value: '$320,000',
    img: prisma,
  },
  {
    id: 10,
    title: 'Rocket Condominium, Main Floor',
    location: 'Toronto, Ontario',
    value: '$N/A',
    img: rocket,
  },
  {
    id: 11,
    title: 'Eastroom - Dreamwork, 68 Claremont Street',
    location: 'Toronto, Ontario',
    value: '$N/A',
    img: claremont,
  },
  {
    id: 12,
    title: '38 Summerhill Gardens, Rosedale',
    location: 'Toronto, Ontario',
    value: '$N/A',
    img: summerhill,
  },
];